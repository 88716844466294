import React from "react";
import s from "./Header.module.css";
import { useLocation, useNavigate } from "react-router-dom"
import Logo from '../../../assets/images/logo.png';
const Header = ({ otherClasses }) => {
const navigate = useNavigate();
  let curLoc = useLocation();

  const companyData = JSON.parse(sessionStorage.getItem('companyData'));

  const toHome = () => {
    if (companyData?.domainurl) {
      window.location.replace(`https://${companyData.domainurl}`);
    } else {
      navigate("/");
    }
  };

  const headerClasses = `${curLoc.pathname === "/"
      ? s.AbsoluteClass
      : curLoc.pathname !== "/thanks" &&
      curLoc.pathname !== "/terms-and-conditions" &&
      s.HideOnMob
    } ${s.Header} ${otherClasses && otherClasses}`;

  return (
    <>
      <header className={headerClasses} >
        {
          companyData ?
            <img
              className={s.HeaderImg + ` HeaderLogo`}
              onClick={toHome}
              alt="logo"
              height={companyData?.theme?.fonts?.logoSize ? companyData?.theme?.fonts?.logoSize : 38}
              src={companyData ? companyData?.logoUrl : Logo}
            /> : null
        }

      </header>
    </>
  );
};
export default Header;
