import React from "react";

//import styles
import s from "./Payment.module.css";

//import components
import { InfoIcon } from "../../svgicons";

//import functions
import {
  CustomInput,
  InputForMap,
  NumberInput,
  PaymentComponent,
  TermsCheck,
} from "../../common";
import GoogleReCaptchaComponent from "../../common/inputs/GoogleReCaptcha";
import CardPayabliComponent from "../../common/payabliPayment";

const BillingInformationComponent = ({
  sameAsTheService,
  data,
  changeAddressCheck,
  setData,
  engineRef,
  setBtnDisable,
  cardErr,
  setTermsChecked,
  termsChecked,
}) => {
  const companyData = JSON.parse(localStorage.getItem('companyData'));
  return (
    <>
      <div className="d-flex align-center justify-content-between">
        <div className="form-title">Billing Information*</div>
        <div className="form-required-label">*Required</div>
      </div>

      <div className="form-body">
        <div className="row">
          <div className="col-md-6">
            <CustomInput
              notDark
              label={"First Name"}
              name="firstName"
              placeholder={""}
            />
          </div>
          <div className="col-md-6">
            <CustomInput
              notDark
              label={"Last Name"}
              name="lastName"
              placeholder={""}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <InputForMap
              notDark
              data={data}
              setData={setData}
              mainClassName={"w-100"}
              label={"Street Address*"}
              name="address"
              placeholder={""}
            />
          </div>
          <div className="col-md-4">
            <CustomInput
              notDark
              label={"Apt or Suite # (optional)"}
              name="suite"
              placeholder={""}
              data={data}
              setData={setData}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <CustomInput
              notDark
              label={"City"}
              name="city"
              placeholder={""}
              data={data}
              setData={setData}
            />
          </div>
          <div className="col-md-3">
            <CustomInput
              notDark
              label={"State"}
              name="state"
              placeholder={""}
              data={data}
              setData={setData}
            />
          </div>
          <div className="col-md-3">
            <CustomInput
              notDark
              label={"Zip Code"}
              name="zipCode"
              type={"number"}
              placeholder={""}
              data={data}
              setData={setData}
            />
          </div>
        </div>

        <div className={s.Spacer}>
          <label className="custom__checkbox">
            My billing and service address are the same
            <input
              className="custom__checkbox--input"
              type="checkbox"
              checked={sameAsTheService}
              onChange={changeAddressCheck}
           // checked
            />
            <span className="custom__checkbox--check"></span>
          </label>
        </div>

        <div className="row">
          <div className="col-md-6">
            <NumberInput
              notDark
              label={"Phone Number"}
              name="phoneNumber"
              placeholder={""}
              type="number"
              maxlength={10}
            />
          </div>
          <div className="col-md-6">
            <CustomInput
              notDark
              label={"Email Address"}
              name="email"
              placeholder={""}
            />
          </div>
        </div>

        <div className="form-title">Payment Information*</div>

        {/* <div className={s.PaymentInformation}>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                notDark
                label={"Credit Card Number"}
                name="creditCard"
                placeholder={""}
                maxlength={16}
              />
            </div>
            <div className="col-md-3">
              <CustomInput
                notDark
                label={"Exp."}
                name="cardExp"
                placeholder={""}
              />
            </div>
            <div className="col-md-3">
              <CustomInput
                notDark
                label={"CVC"}
                name="cardCVC"
                placeholder={""}
                tooltipText="What's this"
                tooltipTitle="more info"
              />
            </div>
          </div>
        </div> */}

        {
          companyData?.paymentGatewayUsed === "PAYABLI" ?
              <CardPayabliComponent
                  engineRef={engineRef}
              /> :
              companyData?.paymentGatewayUsed === "PAY_ENGINE" ?
                  <PaymentComponent
                      cardErr={cardErr}
                      setBtnDisable={setBtnDisable}
                      engineRef={engineRef}
                  /> : null
        }

        <div className={s.SecureInfo}>
          <InfoIcon className={s.Icon} /> A credit card is required to secure
          your reservation. It will not be charged at this time.
        </div>

        <div className="row">
          <div className="col-md-6">
            <GoogleReCaptchaComponent  />
          </div>
        </div>

        <TermsCheck
          termsChecked={termsChecked}
          setTermsChecked={setTermsChecked}
          name="termsChecked"
        />
      </div>
    </>
  );
};
export default BillingInformationComponent;
