import React, { useEffect, useState } from "react";
import s from "./servicedetails.module.css";
import Thumb from "./../../../assets/images/product-thumb.svg";
import { addToCartLocalStorage, isEcommerceEnhancementV2Enabled, getZoneFromSession } from "../../../actions/localstorage";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../../../redux/reducers/appreducer";
import { getSKUsBYCode } from "../../../api/api";
import { getSKUsBYZone } from "../../../apiNew/api";

const ProductSummary = ({ allSkUS }) => {
  let cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const companyData = JSON.parse(sessionStorage.getItem('companyData'));
  const isEcommerceEnhancementEnabled = isEcommerceEnhancementV2Enabled();

  const [skus, setSkus] = useState([]);
  const [data, setData] = useState();

  const zoneData = getZoneFromSession()
  const productList =  zoneData ? zoneData : allSkUS;

  const sortedSkUS = productList.slice().sort((a, b) => 
    a.product.name.localeCompare(b.product.name, undefined, { sensitivity: 'base' })
  );

  const onChange = (e) => {
    const { value } = e.target;
    const selected = productList.filter((sku) => sku.id === value);
    const sum = data.additionalFee.reduce((ent, currentValue) => {
      return (
        ent +
        Number(currentValue?.charge?.value) *
          (currentValue.quantity ? Number(currentValue.quantity) : 0)
      );
    }, 0);
    const cartCreate = {
      ...data,
      ...selected[0],
      ammount: selected[0]?.pricingStructure?.price + sum,
    };
    addToCartLocalStorage(cartCreate);
    dispatch(addProductToCart(cartCreate));
  };

  // If isEcommerceEnhancementEnabled is enabled, fetch data from the server instead of using stored data on the frontend.
  const getZoneRatesMapped = (skuData, zoneRatesMapping) => {
    const mappedSkuList = []; 
    zoneRatesMapping.forEach((zoneRate) => {
      const matchingSku = skuData.find((sku) => sku.id === zoneRate.skuId);
      
      if (matchingSku) {
        mappedSkuList.push({
          ...matchingSku,
          pricingStructure: {
            ...matchingSku?.pricingStructure,
            baseRate: matchingSku?.pricingStructure?.price,
            price: zoneRate?.rate,
            zoneName: zoneRate?.zonename, 
            zoneSkuRateId: zoneRate?.id,
            zoneId: zoneRate?.zoneId
          },
        });
      }
    });
      return mappedSkuList;
  };

  const getData = async (zipcode) => {
    let skus = await getSKUsBYCode(zipcode);
    const overrideZoneRates = await getSKUsBYZone(zipcode);
    if (overrideZoneRates) {
      skus = getZoneRatesMapped(skus, overrideZoneRates);
    }
    const sortedSKUs = skus.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
    setSkus(sortedSKUs);
    const data = skus.find(sku => sku.id === cart.id);
    setData({
      ...cart,
      ...data
    });
  };

  useEffect(() => {
    const zipcode = localStorage.getItem('zipcode');
    if (cart && zipcode && isEcommerceEnhancementEnabled) {
      getData(zipcode);
    } else {
      setData(cart);
    }
  }, [cart]);
  
  return (
    <div className="col-md-4">
      <div className="form-title">Product Summary</div>
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <label className="label-primary">Select Different Product</label>
            <select onChange={onChange} className="custom-select form-control">
              {isEcommerceEnhancementEnabled
                ? skus?.map((v) => (
                  <option key={v.id} value={v.id} selected={v.id === data?.id}
                  >
                    {v.name}
                  </option>
                ))
                : sortedSkUS?.map((v) => (
                  <option key={v.id} value={v.id} selected={v.id === data?.id}
                  >
                    {v.product.name}
                  </option>
                ))}
            </select>
            {/* <div className="invalid-feedback">Error</div> */}
          </div>

          <div className={s.ProductThumb}>
            <img src={companyData?.theme?.productImages?.[data?.product?.id] ? companyData?.theme?.productImages[data?.product?.id] : Thumb} alt="" />
          </div>
          <div className={s.ProductTitle}>{isEcommerceEnhancementEnabled ? data?.name : data?.product?.name }</div>
          <ul className={s.ProductOverviewList}>
            {data?.feeStructure?.weight?.limitType === 'MINIMUM' && data.feeStructure.weight.weightMinimum != null && (
              <li>{`Weight Minimum: ${data.feeStructure.weight.weightMinimum} Ton${data.feeStructure.weight.weightMinimum === 1 ? '' : 's'}`}</li>
            )}
            {data?.feeStructure?.weight?.limitType === 'LIMIT' && data.feeStructure.weight.max != null && (
              <li>{`Weight Allowance: ${data.feeStructure.weight.max} Ton${data.feeStructure.weight.max === 1 ? '' : 's'}`}</li>
            )}
            {/* <li>Length: 23’</li>
            <li>Width: 8’</li>
            <li>Height: 6’</li> */}
          </ul>
        </div>
        <div className={s.ProductNote}>
          Not sure you’re getting the right product? <br />{" "}
          {/*<span>Give us a call and speak with one of our experts today!</span>*/}
          {
            companyData?.theme?.supportContactHTML ?
                <div className={s.contactDetail}>
                  <div dangerouslySetInnerHTML={{__html: companyData.theme.supportContactHTML}}/>
                </div> : null
          }
        </div>
      </div>
    </div>
  );
};

export default ProductSummary;
